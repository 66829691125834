<template>
  <div>
    <section id="topo" class="seccao header-fixo">
      <Header :title="'Pesquisa'" :breadcrums="{}" />
      <section class="search-header">
        <div class="s-header-left">
          <div class="s-input-container">
            <i class="fas fa-search"></i>
            <input
              class="searchInput"
              type="text"
              placeholder="nova pesquisa..."
              v-model="query"
            />
          </div>
        </div>
        <div class="s-header-right">
          <div
            class="select-container"
            style="border: 2px solid #666 !important"
          >
            <i class="fas fa-chevron-down" style="color: #666 !important"></i>
            <select
              v-model="active_area"
              @change="changed_area()"
              style="color: #666 !important"
            >
              <option value="0" selected disabled>Área</option>
              <option value="0" selected>Todas</option>
              <option
                v-for="area in feed_areas"
                :key="'area_' + area.g_area_id"
                :value="area.g_area_id"
              >
                {{ area.name }}
              </option>
            </select>
          </div>

          <div
            class="select-container"
            style="border: 2px solid #666 !important"
          >
            <i class="fas fa-chevron-down" style="color: #666 !important"></i>
            <select v-model="active_category" style="color: #666 !important">
              <option value="0" selected disabled>Categoria</option>
              <option value="0" selected>Todas</option>
              <option
                v-for="category in filtered_categories"
                :key="'category_' + category.g_category_id"
                :value="category.g_category_id"
              >
                {{ category.name }}
              </option>
            </select>
          </div>
          <!-- <div class="select-container">
                   <i class="fas fa-chevron-down"></i>
                   <select>
                       <option>variante 4</option>
                   </select>
               </div> -->
        </div>
      </section>
      <section class="search-main">
        <div id="itemContainer">
          <Searchcard
            v-for="item in filtered_products"
            :key="'product_' + item.id"
            :product_data="item"
          />
        </div>
        <div class="holder"></div>
      </section>
      <Footer />
    </section>

    <div class="loadingLayer" :style="'display: ' + loadingStyle">
      <img src="/assets/imagens/loading.gif" />
      <h3>A carregar...</h3>
    </div>
  </div>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>
<script type="text/javascript" src="@/assets/ficheiros/swiper.min.js"></script>

<script>
  import Header from "@/components/Header.vue";
  import Footer from "@/components/Footer.vue";
  // import Area from '@/components/Area.vue'
  import Searchcard from "@/components/Searchcard.vue";

  export default {
    name: "categoria",
    components: {
      Header,
      Footer,
      //   Area,
      Searchcard,
    },
    data() {
      return {
        breadcrums: {},
        loadingStyle: "flex",
        feed_products: [],

        query: null,

        feed_areas: [],
        active_area: 0,

        feed_categories: [],
        active_category: 0,

        backdoor: 0,
      };
    },
    mounted() {
      if (typeof this.$route.query.q != "undefined") {
        this.query = this.$route.query.q;
      }
    },
    created: async function () {
      await this.collect_feed_data();
      // $( document ).ready(function() {
      //     $("div.holder").jPages({
      //         containerID : "itemContainer",
      //         perPage: 10,
      //     });
      // });
    },
    watch: {
      filtered_products(val) {
        if (val) {
          // this.backdoor++
        }
      },
      "$route.query"(val) {
        this.query = this.$route.query.q;
      },
    },
    computed: {
      filtered_products() {
        this.backdoor;
        let feed = this.feed_products;
        feed = this.feed_products.filter((e) => {
          if (this.query != null && this.query != "") {
            if (e.name.toLowerCase().includes(this.query.toLowerCase())) {
              return true;
            } else {
              let tagValidate;
              for (let tag of e.tags) {
                tag = tag.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                let q_s = this.query.split(" ");
                for (let i in q_s) {
                  if (q_s[i].length > 2) {
                    q_s[i] = q_s[i]
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "");
                    if (tag.toLowerCase().includes(q_s[i])) {
                      return true;
                    }
                  }
                }
              }

              return false;
            }
          } else {
            return true;
          }
        });

        feed = feed.filter((e) => {
          if (this.active_area != 0) {
            return e.g_area_id == this.active_area;
          } else {
            return true;
          }
        });

        feed = feed.filter((e) => {
          if (this.active_category != 0) {
            return e.g_category_id == this.active_category;
          } else {
            return true;
          }
        });

        return feed;
      },
      filtered_categories() {
        let feed = [];
        feed = this.feed_categories.filter((e) => {
          if (this.active_area != 0) {
            return e.area_id == this.active_area;
          } else {
            return true;
          }
        });

        return feed;
      },
    },
    methods: {
      changed_area() {
        this.active_category = 0;
      },
      collect_feed_data: async function () {
        this.loadingStyle = "flex";

        operation = "areas/listAll";
        headers = {};
        url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        const areas = await this.call_api_get(url, headers);

        for (let i in areas.areas) {
          this.feed_areas.push(areas.areas[i]);
        }

        operation = "categories/listAll";
        headers = {};
        url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        const categories = await this.call_api_get(url, headers);

        for (let i in categories.categories) {
          this.feed_categories.push(categories.categories[i]);
        }

        var operation = "product/listAll";
        var headers = {};
        var url = "https://bydservices.pt/glintt-api/public/api/" + operation;

        var feed_data = await this.call_api_get(url, headers);
        for (let e in feed_data.products) {
          // console.log(feed_data.products[e].tags, "tagssss");
          let tags = feed_data.products[e].tags.split("#");
          feed_data.products[e].tags = tags.sort();
          feed_data.products[e].tags = feed_data.products[e].tags.reverse();
        }
        for (let i in feed_data.products) {
          this.feed_products.push(feed_data.products[i]);
        }

        // document.querySelector(".loadingLayer").style.display = "none"

        // operation = "areas/listAll"
        // headers = {}
        // url = 'https://bydservices.pt/glintt-api/public/api/' + operation

        // const families = await this.call_api_get(url, headers)

        // this.feed_families = families.families

        // $( document ).ready(function() {
        //     $("div.holder").jPages({
        //         containerID : "itemContainer",
        //         perPage: 3,
        //     });
        // });

        let vm = this;
        setTimeout(function () {
          vm.loadingStyle = "none";
        }, 500);

        return true;
      },

      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
  };
</script>

<style src="@/assets/style.css"></style>
<style src="@/assets/produtos.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>
<style src="@/assets/ficheiros/jPages.css"></style>

<style scoped>
  .search-header {
    /* width: 90%;
        padding: 30px 5%;
        margin-top: 120px; */
    width: auto;
    padding: 30px 100px;
    margin-top: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .s-header-left {
    display: inline-block;
  }
  .s-input-container {
    position: relative;
  }
  .s-input-container input::placeholder {
    font-size: 17px;
  }
  .searchInput {
    border-bottom-color: #ea8822;
    padding: 8px 35px;
    width: 210px;
  }
  .fa-search {
    color: #ea8822;
    display: inline-block;
    position: absolute;
    font-size: 22px;
    bottom: 9px;
  }
  .s-header-right {
    width: auto;
    display: inline-block;
    text-align: right;
  }
  .select-container {
    display: inline-block;
    width: 198px;
    height: 40px;
    transform: skew(-10deg);
    border: 1.5px solid #b7b9bb;
    margin-left: -1.5px;
    padding: 0 15px;
    border: 2px solid #999 !important;
  }
  .select-container select {
    transform: skew(10deg);
    appearance: none;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    position: relative;
    display: inline-block;
    color: #b7b9bb;
    font-size: 13px;
    color: #999 !important;
  }
  .select-container i {
    position: absolute;
    font-size: 17px;
    transform: skew(10deg);
    top: 12px;
    right: 20px;
    color: #b7b9bb;
  }
  .search-main {
    padding: 0px 100px;
  }
  .swiper-pagination-bullet-active,
  .swiper-pagination-bullet {
    display: none;
  }
  .swipe-menu {
    position: relative;
    width: 100%;
    height: auto !important;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .links {
    color: inherit;
    text-decoration: none;
  }
  .video-container {
    display: flex;
    background: #000;
  }
  /* .video-container {
        display: flex;
        justify-content: center;
        width: 100%;
        background: #000 !important;
        background-size: cover;
    } */
  .prod-video {
    max-height: 100%;
    height: 100%;
    align-self: center;
  }
  .slider-swiper-button-next {
    background-image: url("/assets/imagens/arrow_next_gray.svg");
  }
  @media screen and (max-width: 1250px) {
    .searchInput {
      margin-bottom: 20px;
    }
    .fa-search {
      bottom: 30px;
    }
    /* .s-header-left{
            width: 100%;
            margin: 0 auto;
        } 
        .s-header-right{
            width: 100%;
            text-align: center;
        } */
  }
  @media screen and (max-width: 1024px) {
    .search-header {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
    /* .select-container{
            width: 20%;
            padding: 0 2%;
        } */
  }
  @media only screen and (max-width: 640px) {
    .produtos-wrapper .produto-item {
      min-width: 100%;
    }
    .seccao main .main-inner-wrapper .main-coluna-100p {
      max-width: calc(100vw - 100px) !important;
    }
    .produtos-wrapper .links {
      width: 100%;
    }
    .seccao.header-fixo main.listagem-produtos-com-footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .produtos-wrapper.swiper-wrapper {
      width: 100%;
    }
    .produtos-swiper-button-prev {
      left: 5px;
    }
    .produtos-swiper-button-next {
      right: 5px;
    }
    .prod-video {
      max-height: 100%;
      height: auto;
      width: 100%;
      align-self: center;
    }
    .search-main {
      padding: 0px 40px;
    }
    .search-header {
      padding: 30px 60px;
    }
  }
  @media screen and (max-width: 512px) {
    /* .select-container{
            width: 45%;
            padding: 0 2%;
            margin-bottom: 10px;
        } */
    .s-input-container input {
      width: 200px;
    }
  }
  .holder {
    text-align: center;
  }
  .holder a {
    /* font-category: "Calibri Bold", sans-serif!important; */
    color: #cccecf !important;
    font-size: 30px !important;
  }
</style>
