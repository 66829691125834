var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{key:'/produto/' + _vm.product_data.id,staticClass:"links",attrs:{"to":{
    path:
      '/produto/' +
      _vm.product_data.id +
      '-' +
      _vm.product_data.name.replaceAll(' ', '-').toLowerCase(),
  }}},[_c('article',[_c('div',{staticClass:"color-bg",style:({ backgroundColor: _vm.product_data.area_color })}),_c('figure',[_c('img',{attrs:{"src":'assets/fotos_catalogo/products/' + _vm.product_data.image_1}})]),_c('div',{staticClass:"text-container"},[_c('h2',{staticClass:"title",attrs:{"id":'item_' + _vm.product_data.g_product_id}},[_vm._v(" "+_vm._s(_vm.product_data.name)+" ")]),_c('p',{staticClass:"color-blue area-name",style:({ color: _vm.product_data.area_color })},[_vm._v(" "+_vm._s(_vm.product_data.area_name)+" ")]),_c('p',{staticStyle:{"width":"80%"}},[_vm._v(_vm._s(_vm.product_data.description))]),_c('ul',{staticStyle:{"width":"80%"}},_vm._l((_vm.product_data.tags),function(tag){return _c('li',{key:'item_' + _vm.product_data.g_product_id + '_' + tag},[(tag != '')?_c('a',[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(tag != '')?_c('p',[_vm._v(_vm._s(tag))]):_vm._e()])}),0),(_vm.product_data.price == 0.01)?_c('span',{staticClass:"price-subtitle"}):_vm._e(),(_vm.product_data.price == 0.01)?_c('span',{staticClass:"price",style:({ color: _vm.product_data.area_color })},[_vm._v("Oferta")]):_vm._e(),(
          _vm.product_data.price != 0.0 &&
          _vm.product_data.price != 0.01 &&
          _vm.isLogged != undefined
        )?_c('span',{staticClass:"price-subtitle",style:({ color: _vm.product_data.area_color })},[_vm._v("desde")]):_vm._e(),(
          _vm.product_data.price != 0.0 &&
          _vm.product_data.price != 0.01 &&
          _vm.isLogged != undefined
        )?_c('span',{staticClass:"price",style:({ color: _vm.product_data.area_color })},[_vm._v(_vm._s(parseInt(_vm.product_data.price) .toFixed(0) .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."))+"€")]):_vm._e(),(_vm.product_data.price == 0.0 && _vm.isLogged == undefined)?_c('span',{staticClass:"price-subtitle"}):_vm._e(),(_vm.product_data.price == 0.0 && _vm.isLogged == undefined)?_c('span',{staticClass:"price",style:({ color: _vm.product_data.area_color })},[_vm._v("Sob consulta")]):_vm._e(),_c('div',{staticClass:"btn-container",style:({ border: '2px solid ' + _vm.product_data.area_color })},[_c('router-link',{key:'/produto/' + _vm.product_data.id,staticClass:"links",attrs:{"to":{
            path:
              '/produto/' +
              _vm.product_data.id +
              '-' +
              _vm.product_data.name.replaceAll(' ', '-').toLowerCase(),
          }}},[_c('button',{staticClass:"hover",style:({ color: _vm.product_data.area_color })},[_vm._v(" Saiba mais ")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }