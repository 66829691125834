<template>
  <router-link
    class="links"
    :key="'/produto/' + product_data.id"
    :to="{
      path:
        '/produto/' +
        product_data.id +
        '-' +
        product_data.name.replaceAll(' ', '-').toLowerCase(),
    }"
  >
    <article>
      <div
        class="color-bg"
        :style="{ backgroundColor: product_data.area_color }"
      ></div>
      <figure>
        <img :src="'assets/fotos_catalogo/products/' + product_data.image_1" />
      </figure>
      <div class="text-container">
        <h2 class="title" :id="'item_' + product_data.g_product_id">
          {{ product_data.name }}
        </h2>
        <p
          class="color-blue area-name"
          :style="{ color: product_data.area_color }"
        >
          {{ product_data.area_name }}
        </p>
        <p style="width: 80%">{{ product_data.description }}</p>
        <ul style="width: 80%">
          <li
            v-for="tag in product_data.tags"
            :key="'item_' + product_data.g_product_id + '_' + tag"
          >
            <a v-if="tag != ''"><i class="fas fa-plus"></i></a>
            <p v-if="tag != ''">{{ tag }}</p>
          </li>
        </ul>

        <span class="price-subtitle" v-if="product_data.price == 0.01"></span>
        <span
          class="price"
          :style="{ color: product_data.area_color }"
          v-if="product_data.price == 0.01"
          >Oferta</span
        >

        <span
          class="price-subtitle"
          :style="{ color: product_data.area_color }"
          v-if="
            product_data.price != 0.0 &&
            product_data.price != 0.01 &&
            isLogged != undefined
          "
          >desde</span
        >
        <span
          class="price"
          :style="{ color: product_data.area_color }"
          v-if="
            product_data.price != 0.0 &&
            product_data.price != 0.01 &&
            isLogged != undefined
          "
          >{{
            parseInt(product_data.price)
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
          }}€</span
        >

        <span
          class="price-subtitle"
          v-if="product_data.price == 0.0 && isLogged == undefined"
        ></span>
        <span
          class="price"
          :style="{ color: product_data.area_color }"
          v-if="product_data.price == 0.0 && isLogged == undefined"
          >Sob consulta</span
        >

        <div
          class="btn-container"
          :style="{ border: '2px solid ' + product_data.area_color }"
        >
          <router-link
            class="links"
            :key="'/produto/' + product_data.id"
            :to="{
              path:
                '/produto/' +
                product_data.id +
                '-' +
                product_data.name.replaceAll(' ', '-').toLowerCase(),
            }"
          >
            <button :style="{ color: product_data.area_color }" class="hover">
              Saiba mais
            </button>
            <!-- <div
              class="icon-container"
              :style="{ backgroundColor: product_data.area_color }"
            >
              <img src="@/assets/imagens/icone-carrinho.svg" />
            </div> -->
          </router-link>
        </div>
      </div>
    </article>
  </router-link>
</template>

<script
  type="text/javascript"
  src="@/assets/ficheiros/jquery-3.4.1.min.js"
></script>

<script>
  export default {
    name: "Searchcard",
    props: ["product_data"],
    data() {
      return {
        isLogged: window.sessionStorage.user_id,
        // product_data: {},
        // area_data: {}
      };
    },
    mounted() {
      // this.collect_product_data()
      var styleNode = document.createElement("style");
      styleNode.type = "text/css";
      var styleText = document.createTextNode(
        "#item_" +
          this.product_data.g_product_id +
          ":after {background-color: " +
          this.product_data.area_color +
          " !important;}:root {--cor-principal: #2fb68e !important;--cor-1: #2fb68e !important;}"
      );
      styleNode.appendChild(styleText);

      document.getElementsByTagName("head")[0].appendChild(styleNode);
    },
    methods: {
      call_api_get: async function (url, headers) {
        try {
          var response = await fetch(url, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          var result = await response.json();
          return result;
        } catch (error) {
          console.error("Error:", error);
          return "Error: " + error;
        }
      },
    },
  };
</script>

<style src="@/assets/home.css"></style>
<style src="@/assets/responsive.css"></style>
<style src="@/assets/ficheiros/swiper.min.css"></style>
<style src="@/assets/ficheiros/font-awesome.min.css"></style>

<style scoped>
  .hover:hover {
    cursor: pointer;
  }
  article {
    width: 100%;
    padding: 0px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    min-height: 325px;
    margin-bottom: 50px;
    /* background: rgb(255,255,255);
        background: linear-gradient(110deg, rgba(255,255,255,1) 75%, rgba(246,246,246,1) 75%);
        background-position: right; */
  }
  .color-bg {
    background-color: #214496;
    transform: skew(-10deg);
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  figure {
    position: relative;
    z-index: 3;
    overflow: hidden;
    width: 350px;
    height: 250px;
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
    padding: 0px;
  }
  figure img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .text-container {
    /* position: relative; */
    z-index: 3;
    display: inline-block;
    width: 50%;
    margin-left: 3%;
    vertical-align: middle;
  }

  .title {
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 30px;
    margin-bottom: 12px;
  }

  .title:after {
    content: "";
    background-color: #214496;
    height: 6px;
    width: 40px;
    margin-top: 10px;
    display: block;
  }

  p {
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 20px;
  }

  .area-name {
    font-weight: bold;
  }

  .color-blue {
    color: #214496;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 20px;
  }
  li {
    display: inline-block;
    margin-right: 20px;
  }

  li a {
    border: 2px solid #b7b9bb;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-right: 8px;
  }

  li i {
    display: inline-block;
    line-height: 15px;
    font-size: 10px;
    color: #b7b9bb;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  li p {
    display: inline-block;
    color: #b7b9bb;
    line-height: 15px;
    vertical-align: top;
    font-size: 13px;
  }

  .price-subtitle {
    color: #214496;
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 16px;
    display: block;
    font-weight: 600;
    line-height: 18px;
    margin-top: 15px;
  }

  .price {
    color: #214496;
    /* font-family: "Calibri Bold", sans-serif; */
    font-size: 24px;
    display: block;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .btn-container {
    position: absolute;
    /* right: -8vw; */
    right: 10px;
    bottom: 20px;
    border: 2px solid #214496;
    transform: skew(-10deg);
    width: 200px;
    height: 45px;
  }

  .icon-container {
    position: absolute;
    right: -1px;
    top: -1px;
    background-color: #214496;
    width: 41px;
    height: 47px;
    border: none;
  }

  .icon-container img {
    width: 34px;
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%) skew(10deg);
    /* font-size: 21px;
        margin-top: 9px;
        margin-left: 8px;
        color: white; */
  }

  .btn-container button {
    font-family: inherit;
    font-weight: 500;
    transform: skew(10deg);
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    color: #214496;
    text-align: center !important;
    font-size: 16px;
  }

  @media screen and (max-width: 1350px) {
    article {
      padding: 20px 10px;
    }
    .text-container {
      margin-left: 30px;
    }
  }
  @media screen and (max-width: 512px) {
    figure {
      width: 90%;
      height: 200px;
    }
    .color-bg {
      height: 0px;
    }
    .text-container {
      width: 90%;
    }
    .btn-container {
      position: relative;
      left: 0;
      margin-top: 20px;
    }

    .title {
      font-size: 30px;
    }
    p {
      font-size: 14px;
    }
  }
</style>
